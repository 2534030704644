<template>
    <div class="about py-50">
        <v-sheet>
            <v-container>
                <v-row no-gutters>
                    <v-col cols="12" sm="6">
                        <v-card-title>Welcome to Authcode.</v-card-title>
                        <v-card-text>The home of real product bundles. Since few years, We've been researching to create experimental model where the journey continues. To make use of skills as easy as possible, we use all the available technologies and the high skilled expertise. We rely on our expertise and specialised industry experience to develop any type of web, mobile, desktop, and hybrid product as per your business requirements. We are promising and highly fexible to suite your project of any domain. convinent and favourable to change the strategy as per the requirements.</v-card-text>
                    </v-col>
                    <v-col cols="6" class="visible-sm-and-up">
                        <v-img src="../assets/frontpage.png"></v-img>
                    </v-col>
                </v-row>
            </v-container>
        </v-sheet>
        <v-sheet>
            <v-container class="py-50">
                <v-row justify="center">
                    <v-col cols="12" sm="8" md="7">
                        <v-card-title class="justify-center">AUTHCODE SMART MODEL</v-card-title>
                        <v-img src="../assets/model.png"></v-img>
                    </v-col>
                </v-row>
            </v-container>
        </v-sheet>
        <v-sheet dark>
            <v-container class="py-50">
                <v-row cols="12">
                    <v-card-text> Our consultancy has a special focus on testing competency, also helps in training and job supporting features. Our features and principles includes:</v-card-text>
                </v-row>
                <v-row no-gutters>
                    <v-col cols="12" md="4">Priority support</v-col>
                    <v-col cols="12" md="4">Effortless sclability</v-col>
                    <v-col cols="12" md="4">Easy sign-on</v-col>
                    <v-col cols="12" md="4">Experienced cross functional teams</v-col>
                    <v-col cols="12" md="4">Concurrent and continous deployment</v-col>
                    <v-col cols="12" md="4">Leverage to easily integrate your teams into authcode</v-col>
                    <v-col cols="12" md="4">On-call support</v-col>
                    <v-col cols="12" md="4">Speed up your release - end to end</v-col>
                    <v-col cols="12" md="4">High response time sla's</v-col>
                    <v-col cols="12" md="4">Affirm architecture - All in one</v-col>
                    <v-col cols="12" md="4">Flexible automation - As you like</v-col>
                    <v-col cols="12" md="4">Seamlessly integrates with your toolchain</v-col>
                    <v-col cols="12" md="4">High quality and low maintanence</v-col>
                    <v-col cols="12" md="4">Code collabration and monitoring</v-col>
                    <v-col cols="12" md="4">Access control systems</v-col>
                </v-row>
                <v-row cols="12">
                    <v-card-text>We push you inside a protective chamber and provide you an extra support. We highly believe in customer satisfaction.</v-card-text>
                </v-row>
            </v-container>
        </v-sheet>
        <v-sheet>
            <v-container class="py-50">
                <v-row justify="center">
                    <v-col cols="12" sm="10" md="8">
                        <v-card-title class="justify-center">OUR FRAMEWORK</v-card-title>
                        <v-img src="../assets/framework.png"></v-img>
                    </v-col>
                </v-row>
            </v-container>
        </v-sheet>
        <ContactParallax />
        <v-sheet>
            <v-container>
                <v-row align="center">
                    <v-col cols="12" md="5">
                        <v-card-title>OUR PROCESS</v-card-title>
                        <v-card-text>
                            <p>We use already reserached and proven concepts to your projects to build as per your requirements. Authcode strongly rely on the parallelism structure using robotic framework. we create virtual machines and provide the code to machine to invoke the necessary operation. our framework consist of inbuild diagnoistics and tracer which works on the powerful condition based business flow. The reports are customizable and uses industrial standard analytics.</p>
                            <p>Our process is very transparent and easy to adapt. Any new changes can also be added smoothly. Your requirements will be transformed to our templates with major function points. This is next uploaded into our smart builder, which brings up with all the scenario's based on our internal mapping. This scenario's are ready to be reviewed and undergoes feasability check. After approval this highlevel structure is given to the smart Mixer which splits the high level scenario to various tasks and subtasks based on the conditions given. Automatically the tasks are separated based on the priorities and the similar function points are grouped together building iterations. Our controller periodically checks whether the development is 100% in track with the requirement. we have a 24/7 monitoring process which sends report every time for any commit assoicated with the project development to the team members.</p>
                        </v-card-text>
                    </v-col>
                    <v-col cols="12" md="7">
                        <v-img src="../assets/process.png"></v-img>
                    </v-col>
                </v-row>
            </v-container>
        </v-sheet>
    </div>
</template>

<script>
import ContactParallax from '@/components/ContactParallax.vue'

export default {
    name: 'About',
    components: {
        ContactParallax
    }
}

</script>